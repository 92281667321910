<template>
  <div class="locale-switcher">
      <v-row>
          <!-- <select class="white--text" :value="$i18n.locale" @change.prevent="changeLocale">
            <option class="black--text" :value="locale.code" v-for="locale in locales" :key="locale.code">{{locale.name}}</option>
          </select> -->
          <v-btn
              icon
              :value="$i18n.locale"
              @click="changeLocale"
            >
              {{ $i18n.locale }}
            </v-btn>
      </v-row>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"
export default {
  data: () => ({ locales: getSupportedLocales() }),
  methods: {
    changeLocale(e) {
    //   console.log(e.value)
    //   const locale = e.target.value
      var locale = e.value
      if (this.$i18n.locale == 'en') {
        locale = 'ru'
      } else {
        locale = 'en'
      }
      this.$router.push(`/${locale}`)
    }
  }
}
</script>